import { GetServerSideProps, GetServerSidePropsResult } from 'next';
import React, { useMemo } from 'react';
import { getPage, getSiteSettings } from '../lib/api';
import type { APIResponse, umbraco } from '../lib/api';
import { ErrorHandlingProvider } from '../error-handling';
import { getInstrumentationKey } from '../utils/application-insights.helpers';
import { ssrForProductSpots, ssrForSalesVideo } from '../lib/ssr-helpers';
import { useCookiebot } from '../hooks/use-cookiebot';
import { useUrlHandler } from '../hooks/use-url-handler';
import { filterSpotsInUnknownPageType } from '../components/spots/helpers';
import dynamic from 'next/dynamic';
import { HesselSiteProps } from '../components/site/hessel-site.component';
import { getProtocol } from '../utils/helpers';
import { SalesVideoData } from '../lib/api/models/hessel-api/sales-video';

type Props = {
    page: umbraco.Page;
    siteSettings: umbraco.SiteSettings;
    salesVideoData: SalesVideoData | null;
};

const DynamicHesselSite = dynamic<HesselSiteProps>(() => import('../components/site/hessel-site.component').then((module) => module.HesselSite));

const getSiteComponents = (props: Props) => {
    const updatedSettings = {
        ...props.siteSettings,
        marketSettings: props.siteSettings.marketSettings.map((marketSetting) => {
            if (props.page.contentTypeAlias !== 'plusSubscriptionPage' && props.page.contentTypeAlias !== 'carSaverRootPage') {
                return marketSetting;
            }
            return {
                ...marketSetting,
                reepaySettings: {
                    reepayFormContent: props.page.reepayFormContent,
                    serviceComparison: props.page.serviceComparison,
                },
            };
        }),
    };
    return <DynamicHesselSite page={props.page} salesVideo={props.salesVideoData} siteSettings={updatedSettings} />;
};

const DynamicPage = (props: Props): JSX.Element => {
    useCookiebot();
    useUrlHandler();

    /**
     * Note: Make sure to Add Error messages to the newly added site (In Umbraco).
     */
    const errors = useMemo(() => {
        return props.siteSettings.marketSettings
            .map(({ errorContent }) => errorContent.children)
            .reduce((acc, errorInfo) => [...acc, ...errorInfo], []);
    }, [props.siteSettings]);

    return <ErrorHandlingProvider errors={errors}>{getSiteComponents(props)}</ErrorHandlingProvider>;
};

const SITE_ID = process.env.NEXT_PUBLIC_SITE_ID;

const errorGuidePrefix = `[EJH-SPA]-${SITE_ID}-[getServerSideProps]`;

export const getServerSideProps: GetServerSideProps = async ({ params, query, req }): Promise<GetServerSidePropsResult<Props>> => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const appInsights = require('applicationinsights');
    appInsights.setup(getInstrumentationKey());
    appInsights.start();
    const client = appInsights.defaultClient;

    if (!params) {
        return {
            notFound: true,
        };
    }

    let slug = '';
    if (params.slug) {
        if (Array.isArray(params.slug)) {
            slug += params.slug.join('/');
        } else {
            slug += params.slug;
        }
    }
    const protocol = getProtocol(req.headers.host?.toString() ?? '');
    const canonical = `${protocol}${req.headers.host?.toString() ?? ''}/${slug}`;

    const [siteSettings, siteSettingsError] = await getSiteSettings();
    const [page, pageError] = await handleLinkedContentPage(getPage(slug));

    if (siteSettingsError) {
        client.trackException({
            exception: new Error(
                `${errorGuidePrefix}-[SLUG]:${slug}-[getSiteSettings]: ${siteSettingsError.errorType} - ${siteSettingsError.statusCode} `
            ),
        });
    }

    if (pageError) {
        client.trackException({
            exception: new Error(`${errorGuidePrefix}-[SLUG]:${slug}-[getPage]: ${pageError.errorType} - ${pageError.statusCode}`),
        });
    }

    if (!siteSettings) {
        console.log("Redirecting to 404 because siteSettings doesn't exist");
        client.trackException({ exception: new Error(`${errorGuidePrefix}-[SLUG]:${slug}-getSiteSettings returned no data`) });
        return {
            notFound: true,
        };
    }

    if (!page) {
        console.log("Redirecting to 404 because page doesn't exist");
        client.trackException({ exception: new Error(`${errorGuidePrefix}-[SLUG]:${slug}-getPage returned no data`) });
        return {
            notFound: true,
        };
    }

    if (
        page.contentTypeAlias === 'hireRootPage' ||
        page.contentTypeAlias === 'contentPage' ||
        page.contentTypeAlias === 'hesselRootPage' ||
        page.contentTypeAlias === 'plusSubscriptionPage' ||
        page.contentTypeAlias === 'shopCategoryPage' ||
        page.contentTypeAlias === 'salesVideoPage'
    ) {
        const globalPlpSettings = siteSettings.marketSettings[0].productListSettings;
        if (globalPlpSettings) {
            await ssrForProductSpots(page, globalPlpSettings, query);
        }
    }

    if (page.contentTypeAlias === 'bookingMainPage' && page.redirectUrl?.url && page.enableRedirect) {
        return {
            redirect: {
                permanent: false,
                destination: page.redirectUrl.url,
            },
        };
    }

    await filterSpotsInUnknownPageType(page);

    let salesVideoData = null;

    if (page.contentTypeAlias === 'salesVideoPage') {
        salesVideoData = await ssrForSalesVideo(query);
        if (!salesVideoData) return { notFound: true };
    }

    return {
        props: {
            page: { ...page, canonical },
            siteSettings,
            salesVideoData,
        },
    };
};

const handleLinkedContentPage = async (response: APIResponse<umbraco.Page>): APIResponse<umbraco.Page> => {
    const [page, error] = await response;
    if (page?.contentTypeAlias !== 'linkedContentPage') return [page, error];

    const linkedContentPage = page.contentPicker;
    linkedContentPage.breadcrumbs = page.breadcrumbs;
    linkedContentPage.name = page.name;
    return [linkedContentPage, error];
};

export default DynamicPage;
