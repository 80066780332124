import { useEffect } from 'react';
import { UserConsentStore } from '../lib/state/user-consent/user-consent.store';

export const useCookiebot = (): void => {
    const { cookieBotAccepted } = UserConsentStore.useStoreState((state) => state);
    const { setCookieBotAccepted } = UserConsentStore.useStoreActions((actions) => actions);

    useEffect(() => {
        const userAcceptedCookieBot = window.Cookiebot?.consent?.marketing;
        if (userAcceptedCookieBot && !cookieBotAccepted) {
            setCookieBotAccepted(true);
        }

        window.addEventListener(
            'CookiebotOnAccept',
            function (e) {
                console.log('Event handler: ', e);
                if (window.Cookiebot?.consent?.marketing) {
                    setCookieBotAccepted(true);
                }
            },
            false
        );
    }, [cookieBotAccepted, setCookieBotAccepted]);
};
